import rotation from './rotation';

const complement = (baseColor, key) => {
  if (typeof key !== 'number' || key === 0) return baseColor;
  let angle = 0;
  let direction = key < 0 ? -1 : 1;
  let i = 0;

  while (i !== key) {
    i += direction;
    if (i % 2 !== 0) angle += 180 * direction;else angle += 30 * direction;
  }

  return rotation(baseColor, angle);
};

export default complement;