import { isHsl } from './hsl';
import { normalizeRgb } from './rgb';
import hslToRgb from './transforms/hslToRgb';

function roundRgb(rgb) {
  return {
    r: Math.round(rgb.r),
    g: Math.round(rgb.g),
    b: Math.round(rgb.b),
    a: rgb.a
  };
}

function rgbToHexString(rgb) {
  const int = ((Math.round(rgb.r) & 0xff) << 16) + ((Math.round(rgb.g) & 0xff) << 8) + (Math.round(rgb.b) & 0xff);
  const str = int.toString(16).toUpperCase();
  const padLeft = '000000'.substring(str.length);
  return `#${padLeft}${str}`;
}

function rgbToRgbaString(rgb) {
  rgb = roundRgb(rgb);
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`;
}

export default function colorString(color) {
  const rgbColor = isHsl(color) ? hslToRgb(color) : normalizeRgb(color);
  return rgbColor.a === 1 ? rgbToHexString(rgbColor) : rgbToRgbaString(rgbColor);
}