import colorString from '../color/colorString';
import hsl from '../color/hsl';

const rotation = (baseColor, key) => {
  if (typeof key !== 'number' || key === 0) return baseColor;
  const base = hsl(baseColor);
  const targetH = (base.h + key) % 360;
  return colorString(Object.assign(Object.assign({}, base), {
    h: targetH
  }));
};

export default rotation;