import { exact, extractValuesFromMatch } from './utils';
const hex = /[0-9a-fA-F]/.source;
export const hexColorMatcher = new RegExp(`#(${hex}{2})(${hex}{2})(${hex}{2})(${hex}{2})?`);
export const shortHexColorMatcher = new RegExp(`#(${hex})(${hex})(${hex})(${hex})?`);
export function isHexString(colorString) {
  return exact(hexColorMatcher).test(colorString) || exact(shortHexColorMatcher).test(colorString);
}
export function matchHexString(colorString) {
  var _a;

  const match = (_a = exact(hexColorMatcher).exec(colorString)) !== null && _a !== void 0 ? _a : exact(shortHexColorMatcher).exec(colorString);
  return match ? extractValuesFromMatch(match) : null;
}