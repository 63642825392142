import { normalizeHsl } from '../hsl';
export default function hslToRgb(hsl) {
  const {
    h,
    s,
    l,
    a
  } = normalizeHsl(hsl);
  const hue = h || 0;
  const sat = s / 100;
  const light = l / 100;

  function f(n) {
    const k = (n + hue / 30) % 12;
    const a = sat * Math.min(light, 1 - light);
    return light - a * Math.max(-1, Math.min(k - 3, 9 - k, 1));
  }

  return {
    r: f(0) * 255,
    g: f(8) * 255,
    b: f(4) * 255,
    a
  };
}