import { clamp } from '../utils';
import angle, { normalizeAngle } from './angle';
import { matchHslString } from './parsers/hslString';
import { rgbFromColorString } from './rgb';
import rgbToHsl from './transforms/rgbToHsl';
export function isHsl(color) {
  return typeof color.h === 'number' && typeof color.s === 'number' && typeof color.l === 'number' && typeof color.a === 'number';
}
export function normalizeHsl(hsl) {
  return {
    h: normalizeAngle(hsl.h),
    s: clamp(hsl.s, 0, 100),
    l: clamp(hsl.l, 0, 100),
    a: clamp(hsl.a, 0, 1)
  };
}

function hslFromParsedHslString(match) {
  var _a, _b;

  const hslValues = match.map(val => parseFloat(val));
  let alpha = (_a = hslValues[3]) !== null && _a !== void 0 ? _a : 1;

  if (((_b = match[3]) === null || _b === void 0 ? void 0 : _b.indexOf('%')) > -1) {
    alpha *= 0.01;
  }

  return normalizeHsl({
    h: angle(match[0]),
    s: hslValues[1],
    l: hslValues[2],
    a: alpha
  });
}

function hslFromRgbString(colorString) {
  const rgbColor = rgbFromColorString(colorString);
  return rgbColor ? rgbToHsl(rgbColor) : null;
}

export function hslFromColorString(colorString) {
  colorString = colorString.trim();
  let match;
  if ((match = matchHslString(colorString)) !== null) return hslFromParsedHslString(match);
  return null;
}
export default function hsl(colorString) {
  var _a;

  const hslObj = (_a = hslFromColorString(colorString)) !== null && _a !== void 0 ? _a : hslFromRgbString(colorString);
  if (hslObj === null) throw new Error('Invalid color string');
  return hslObj;
}