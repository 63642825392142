import { normalizeRgb } from '../rgb';
export default function rgbToHsl(rgb) {
  const {
    r,
    g,
    b,
    a
  } = normalizeRgb(rgb);
  const red = r / 255;
  const green = g / 255;
  const blue = b / 255;
  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);
  const d = max - min;
  const light = (min + max) / 2;
  let hue = NaN;
  let sat = 0;

  if (d !== 0) {
    sat = (max - light) / Math.min(light, 1 - light);

    switch (max) {
      case red:
        hue = (green - blue) / d + (green < blue ? 6 : 0);
        break;

      case green:
        hue = (blue - red) / d + 2;
        break;

      case blue:
        hue = (red - green) / d + 4;
    }

    hue *= 60;
  }

  return {
    h: hue,
    s: sat * 100,
    l: light * 100,
    a
  };
}