import { alphaSeparatorMatcher, cssNumberMatcher, exact, extractValuesFromMatch, separatorMatcher } from './utils';
const num = cssNumberMatcher.source;
const sep = separatorMatcher.source;
const asep = alphaSeparatorMatcher.source;
export const hslMatcher = new RegExp(`hsla?\\(\\s*(${num}(?:deg|rad|grad|turn)?)${sep}(${num})%${sep}(${num})%(?:${asep}(${num}%?))?\\s*\\)`, 'i');
export function isHslString(colorString) {
  return exact(hslMatcher).test(colorString);
}
export function matchHslString(colorString) {
  const match = exact(hslMatcher).exec(colorString);
  return match ? extractValuesFromMatch(match) : null;
}