import { alphaSeparatorMatcher, cssNumberMatcher, exact, extractValuesFromMatch, separatorMatcher } from './utils';
const num = cssNumberMatcher.source;
const sep = separatorMatcher.source;
const asep = alphaSeparatorMatcher.source;
export const rgbMatcher = new RegExp(`rgba?\\(\\s*(${num}%?)${sep}(${num}%?)${sep}(${num}%?)(?:${asep}(${num}%?))?\\s*\\)`, 'i');
export function isRgbString(colorString) {
  return exact(rgbMatcher).test(colorString);
}
export function matchRgbString(colorString) {
  const match = exact(rgbMatcher).exec(colorString);
  return match ? extractValuesFromMatch(match) : null;
}