import { clamp } from '../utils';
import { hslFromColorString } from './hsl';
import named from './named';
import { matchHexString } from './parsers/hexString';
import { matchRgbString } from './parsers/rgbString';
import hslToRgb from './transforms/hslToRgb';
export function isRgb(color) {
  return typeof color.r === 'number' && typeof color.g === 'number' && typeof color.b === 'number' && typeof color.a === 'number';
}
export function normalizeRgb(rgb) {
  return {
    r: clamp(rgb.r, 0, 255),
    g: clamp(rgb.g, 0, 255),
    b: clamp(rgb.b, 0, 255),
    a: clamp(rgb.a, 0, 1)
  };
}

function rgbFromParsedHexString(match) {
  var _a;

  const rgbValues = match.map(val => {
    if (val.length === 1) {
      val = `${val}${val}`;
    }

    return parseInt(val, 16);
  });
  const alpha = ((_a = rgbValues[3]) !== null && _a !== void 0 ? _a : 255) / 255;
  return {
    r: rgbValues[0],
    g: rgbValues[1],
    b: rgbValues[2],
    a: alpha
  };
}

function rgbFromParsedRgbString(match) {
  var _a;

  const rgbValues = match.map((val, index) => {
    let num = parseFloat(val);

    if (val.indexOf('%') > -1) {
      num *= 0.01;

      if (index < 3) {
        num *= 255;
      }
    }

    return num;
  });
  return normalizeRgb({
    r: rgbValues[0],
    g: rgbValues[1],
    b: rgbValues[2],
    a: (_a = rgbValues[3]) !== null && _a !== void 0 ? _a : 1
  });
}

function rgbFromHslString(colorString) {
  const hslColor = hslFromColorString(colorString);
  return hslColor ? hslToRgb(hslColor) : null;
}

export function rgbFromColorString(colorString) {
  colorString = colorString.trim();
  if (colorString.toLowerCase() === 'transparent') return {
    r: 0,
    g: 0,
    b: 0,
    a: 0
  };
  const hexFromName = named(colorString);

  if (hexFromName) {
    colorString = hexFromName;
  }

  let match;
  if ((match = matchHexString(colorString)) !== null) return rgbFromParsedHexString(match);else if ((match = matchRgbString(colorString)) !== null) return rgbFromParsedRgbString(match);
  return null;
}
export default function rgb(colorString) {
  var _a;

  const rgbObj = (_a = rgbFromColorString(colorString)) !== null && _a !== void 0 ? _a : rgbFromHslString(colorString);
  if (rgbObj === null) throw new Error('Invalid color string');
  return rgbObj;
}