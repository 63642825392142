export function toDegrees(angle, unit) {
  var _a;

  const multiplier = {
    rad: 180 / Math.PI,
    grad: 0.9,
    turn: 360
  };
  return angle * ((_a = multiplier[unit.toLowerCase()]) !== null && _a !== void 0 ? _a : 1);
}
export function normalizeAngle(degrees) {
  return (degrees % 360 + 360) % 360;
}
export default function angle(str) {
  var _a, _b;

  const num = parseFloat(str);
  const unit = (_b = (_a = str.match(/deg|rad|grad|turn/i)) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : 'deg';
  return normalizeAngle(toDegrees(num, unit));
}