import colorString from '../color/colorString';
import rgb from '../color/rgb';

const opacity = (baseColor, key) => {
  if (typeof key !== 'number') return baseColor;
  const base = rgb(baseColor);
  const targetA = Math.min(Math.max(key, 0), 1);
  return colorString(Object.assign(Object.assign({}, base), {
    a: targetA
  }));
};

export default opacity;